import React from 'react';
import monthlyRetainer from './../../../images/developmentModel/Monthly-retainer-1.png';
import monthlyRetainer2 from './../../../images/developmentModel/Monthly-retainer.png';
import './MonthlyRetainer.css';

const MonthlyRetainer = () => {
    return (
        <div className=''>
            <div className='row my-5'>
                <div className='col-md-7'>
                    <img className='my-3' src={monthlyRetainer} alt='...'/>
                    <h2 className='text-start my-3'>Monthly Retainer</h2>
                    <p className='pe-5 me-3'>The monthly retainer model gives the client freedom to purchase hours of the outsourced resource. Retainer contracts are based on an hourly rate of the outsourced personnel and are paid for a specific number of hours they are retained for in a month.</p>
                </div>
                <div className='col-md-5'>
                    <img className='img-fluid w-100' src={monthlyRetainer2} alt='...'/>
                </div>
            </div>
        </div>
    );
};

export default MonthlyRetainer;