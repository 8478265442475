import React from "react";
import { NavLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../../../images/logo.png";
import './CommonNavigation.css';

const CommonNavigation = () => {
  return (
    <div>
      <nav className="fixed-top box-shadow bg-white">
        <div className="container navbar navbar-expand-lg navbar-light my-nav">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
              <img
                src={logo}
                alt="Digital Decoder Ltd"
                width="225"
                height="90"
              />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse d-lg-flex justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-lg-0">
                <li className="nav-item mt-lg-3 mx-2">
                  <NavLink
                    to="/"
                    className="text-black pb-2 mt-2"
                    aria-current="page"
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item mt-lg-3 mx-2">
                  <NavLink
                    to="/services"
                    className="text-black pb-2  mt-2"
                    aria-current="page"
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav-item mt-lg-3 mx-2">
                  <NavLink
                    to="/products"
                    className="text-black pb-2  mt-2"
                    aria-current="page"
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    Products
                  </NavLink>
                </li>
                <li className="nav-item mt-lg-3 mx-2">
                  <NavLink
                    to="/clientsPortfolio"
                    className="text-black pb-2  mt-2"
                    aria-current="page"
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    Clients Portfolio
                  </NavLink>
                </li>
                <li className="nav-item mt-lg-3 mx-2">
                  <NavLink
                    to="/career"
                    className="text-black pb-2 mt-2"
                    aria-current="page"
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    Career
                  </NavLink>
                </li>
                <li className="nav-item mt-lg-3 mx-2">
                  <NavLink
                    to="/about"
                    className="text-black pb-2  mt-2"
                    aria-current="page"
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item mt-lg-3 mx-2">
                  <NavLink
                    to="/team"
                    className="text-black pb-2 mt-2"
                    aria-current="page"
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    Team
                  </NavLink>
                </li>
                <li className="nav-item mt-lg-3 mx-2">
                  <NavLink
                    to="/contact"
                    className="text-black pb-2  mt-2"
                    aria-current="page"
                    style={{ textDecoration: "none", display: "inline-block" }}
                  >
                    Contact
                  </NavLink>
                </li>

                <li className="nav-item  d-none d-lg-flex">
                  <div className="search-menu">
                    <button className="btn">
                      <SearchIcon />
                    </button>
                    <div className="search-menu-container">
                      <form>
                        <input type="search" placeholder="Search..." />
                        <button className="search-btn" type="submit">
                          Search
                        </button>
                      </form>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default CommonNavigation;
