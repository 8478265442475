import React from "react";
import logo from './../../../images/logo.png';
import "./Footer.css";
import { FiPhoneCall } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';
import { ImLocation2 } from 'react-icons/im';
import { IoIosArrowForward } from 'react-icons/io';
import { FaFacebookSquare } from 'react-icons/fa';
import { GrInstagram } from 'react-icons/gr';
import { ImLinkedin } from 'react-icons/im';
import { AiFillTwitterSquare } from 'react-icons/ai';
import { FaRegHandPointRight } from 'react-icons/fa';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>

      <div className="footerWrapper pt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-5">
              <div className="">
                <Link to="#">
                  <img src={logo} width="225" height='90' alt="" />
                </Link>
              </div>
              <div className=" mt-lg-3 mt-md-0 mt-3">
                <FiPhoneCall />
                <a className="px-3 text-black fw-bold" style={{ textDecoration: 'none' }} href="tel:+9613661122">+9613661122</a>
              </div>
              <div>
                <MdEmail className=" d-inline-block" />
                <a className=" px-3 text-black fw-bold" style={{ textDecoration: 'none' }} href="mailto:info@digitaldecoderltd.com">info@digitaldecoderltd.com</a>
              </div>

              <h5 className="mt-3">Corporate Office & Dev. Centre</h5>

              <div className="d-flex align-item-center">
                <ImLocation2 className="fs-1" />
                <div>
                  <p className="px-3">E-9/6, East Tower Chaina Town, Nayapaltan, Dhaka, Bangladesh</p>
                </div>
              </div>
              <hr className="d-none d-lg-none d-md-block" />
            </div>

            <div className="col-md-6 col-lg-4">
              <hr className="d-md-none" />
              <h4 className="my-lg-0 my-md-2">Our Services</h4>
              <div>
                <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> File Converting</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> Accounting Software</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> Hospital Management</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> Institution Management</Link></p>
                {/* <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> SF Management</Link></p> */}
                <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> Documents Clearing</Link></p>
                {/* <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> Point of Sale</Link></p> */}
                <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> Tour and Travels</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <FaRegHandPointRight className="me-2" /> Parking Management</Link></p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <hr className="d-md-none" />
              <h4 className="my-lg-0 my-md-2">Quick Links</h4>
              <div>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> Home</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> Service</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> Products</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> Clients Portfolio</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> Career</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> About</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> Team</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> Contact</Link></p>
                <p className="m-1"><Link className="service-item" to="#"> <IoIosArrowForward /> Parking Management</Link></p>
              </div>
            </div>

          </div>

          <hr />
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-lg-start text-center m-0 p-0">
                <p>Copyright © 2022
                  <Link to='/'>
                    <img src={logo}
                      style={{
                        width: '130px',
                        margin: '0 4px 4px 4px'
                      }}
                      alt="..." />
                  </Link>
                  <br className="d-block d-md-none" />
                  All Rights Reserved</p>
              </div>
            </div>
            <div className="col-lg-6 text-lg-end text-center">
              {/* <hr className="d-lg-none" /> */}
              {/* <h4>Connect with us on</h4> */}
              <div className="mb-3">
                <Link className="px-2" to="#"><FaFacebookSquare style={{ fontSize: '30px', color: '#4867AA', cursor: 'pointer' }} /></Link>
                <Link className="px-2" to="#"><GrInstagram className="instagramColor rounded" style={{ fontSize: '28px', padding: '2px', cursor: 'pointer' }} /></Link>
                <Link className="px-2" to="#"><ImLinkedin style={{ fontSize: '26px', color: '#0077B5', cursor: 'pointer' }} /></Link>
                <Link className="px-2" to="#"><AiFillTwitterSquare style={{ fontSize: '33px', color: '#1DA1F2', cursor: 'pointer' }} /></Link>
              </div>
              <div>
                {/* <Link to="#">
                  <img src={logo} style={{ width: '225px', height: '90px' }} alt="" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* <div className="footer-top">
        <div className="color-overplay"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5">
                <h3>Digital Decoder Ltd.</h3>
              <div className="company-info">
                <p className="mt-3"><PhoneInTalkIcon/> +9613661122</p>
                <p><MailIcon/> info@digitaldecoderltd.com</p>
                <h5 className="mt-4">Corporate Office & Dev. Centre</h5>
                <h6>
                  <LocationOnIcon/> E-9/6, East Tower <br /> <span className="ms-4">Chaina Town, Nayapaltan, Dhaka, Bangladesh</span>
                </h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5">
              <h3>Products</h3>
              <div style={{marginLeft: "-28px"}}>
                <ul className="list-item">
                  <li><a href="#">File Converting</a></li>
                  <li><a href="#">Accounting Software</a></li>
                  <li><a href="#">Hospital Management</a></li>
                  <li><a href="#">Institution Management</a></li>
                  <li><a href="#">SF Management</a></li>
                  <li><a href="#">Documents Clearing</a></li>
                  <li><a href="#">Point of Sale</a></li>
                  <li><a href="#">Tour and Travels</a></li>
                  <li><a href="#">Parking Management</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-5">
              <h3>Services</h3>
              <div style={{marginLeft: "-28px"}}>
                <ul className="list-item">
                  <li><a href="#">Managed Service Provider</a></li>
                  <li><a href="#">Testing as a Service</a></li>
                  <li><a href="#">Infrastructure as a Service</a></li>
                </ul>
              </div>

              <h3 className="mt-3">Industry</h3>
              <div style={{marginLeft: "-28px"}}>
                <ul className="list-item">
                  <li><a href="#">Managed Service Provider</a></li>
                  <li><a href="#">Testing as a Service</a></li>
                  <li><a href="#">Infrastructure as a Service</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2  my-lg-3">
              <span className="fw-light">Copyright © 2022  Digital Decoder Ltd. All Rights Reserved.</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2  my-lg-3">
              <div className="text-lg-end">
                <span><a href="#"><FontAwesomeIcon
                  className="font-icon fb-icon me-3"
                  icon={faFacebook}
                /></a></span>
                <span><a href="#"><FontAwesomeIcon
                  className="font-icon in-icon me-3"
                  icon={faInstagram}
                /></a></span>
                <span><a href="#"><FontAwesomeIcon
                  className="font-icon ln-icon me-5"
                  icon={faLinkedinIn}
                /></a></span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Footer;