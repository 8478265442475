import React from 'react';
import Footer from '../Shared/Footer/Footer';
import Fade from "react-reveal/Fade";
import './Team.css';
import CommonNavigation from '../Shared/CommonNavigation/CommonNavigation';

import profile from '../../images/team/profile.jpg'
// import profile1 from '../../images/team/profile-1.jpg'
// import profile2 from '../../images/team/profile-2.jpg'
// import profile3 from '../../images/team/profile-3.jpg'
// import profile4 from '../../images/team/profile-4.jpg'
// import profile5 from '../../images/team/profile-5.jpg'
import profile6 from '../../images/team/profile-6.jpg'
import { teamData } from '../../Data/teamData';
// import user from '../../images/team/user.png'
// import profile4 from '../../images/manik.png'
import hireIcon from '../../images/hire-icon.png'
import { Link } from 'react-router-dom';
import { FaFacebookSquare } from 'react-icons/fa';
import { GrInstagram } from 'react-icons/gr';
import { ImLinkedin } from 'react-icons/im';
import { BsGithub } from 'react-icons/bs';
import { SiGmail } from 'react-icons/si';

import { AiFillTwitterSquare } from 'react-icons/ai';

const Team = () => {


    return (
        <div>
            <CommonNavigation />
            <div className="product_bg d-flex justify-content-center align-items-center" style={{ marginTop: '92px' }}>
                <Fade top>
                    <h2 className="text-light fw-bolder text-capitalize">Our Team</h2>
                </Fade>
            </div>

            <div className="our-team pt-5">
                <div className="container">
                    <div className="row justify-content-evenly">
                        <div className="col-lg-4">
                            <div class="card profile-card" >
                                <img class="card-img-top" src={profile} alt='' />
                                <div class="card-body">
                                    <h5 class="card-title">Md. A.G. Mahmud</h5>
                                    <p>Chairman</p>
                                </div>
                                {/* <div className="card-description">

                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                                </div> */}
                                <div className="card-social">
                                    <Link className="px-2" to="#"><FaFacebookSquare style={{ fontSize: '30px', color: '#4867AA', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><GrInstagram className="instagramColor rounded" style={{ fontSize: '28px', padding: '2px', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><ImLinkedin style={{ fontSize: '26px', color: '#0077B5', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><AiFillTwitterSquare style={{ fontSize: '33px', color: '#1DA1F2', cursor: 'pointer' }} /></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div class="card profile-card" >
                                <img class="card-img-top" src={profile6} alt='' />
                                <div class="card-body">
                                    <h5 class="card-title">Motaharul Janan</h5>
                                    <p>Managing Director</p>
                                </div>
                                {/* <div className="card-description">

                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                                </div> */}
                                <div className="card-social">
                                    <Link className="px-2" to="#"><FaFacebookSquare style={{ fontSize: '30px', color: '#4867AA', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><GrInstagram className="instagramColor rounded" style={{ fontSize: '28px', padding: '2px', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><ImLinkedin style={{ fontSize: '26px', color: '#0077B5', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><AiFillTwitterSquare style={{ fontSize: '33px', color: '#1DA1F2', cursor: 'pointer' }} /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {teamData && teamData.map((index)=>{
                            return(<div className="col-lg-4">
                            <div class="card profile-card" >
                                <img class="card-img-top" src={index.img} alt='' />
                                <div class="card-body">
                                    <h5 class="card-title">{index.name}</h5>
                                    <p>{index.designation}</p>
                                </div>
                                {/* <div className="card-description">

                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                                </div> */}
                                <div className="card-social">
                                    <Link className="px-2" to="#" onClick={() => (window.open(index.facebook))}><FaFacebookSquare style={{ fontSize: '30px', color: '#4867AA', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#" onClick={() => (window.open(index.github))}><BsGithub style={{ fontSize: '28px', color:'#171515', padding: '2px', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#" onClick={() => (window.open(index.linkedin))}><ImLinkedin style={{ fontSize: '26px', color: '#0077B5', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#" onClick={() => window.location = `mailto:${index.email}`}><SiGmail style={{ fontSize: '33px', color: '#eb2424', cursor: 'pointer' }} /></Link>
                                </div>
                            </div>
                        </div>)
                        })}
                       

                        <div className="col-lg-4">
                            <div class="card profile-card" >
                                <img class="card-img-top" src={hireIcon} alt='' />
                                <div class="card-body">
                                    <h5 class="card-title">We are hiring!</h5>
                                    <Link className='text-danger' to='/hire'><u>Become a part or our team</u></Link>
                                </div>
                                {/* <div className="card-description">

                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>

                                </div> */}
                                <div className="card-social">
                                    {/* <Link className="px-2" to="#"><FaFacebookSquare style={{ fontSize: '30px', color: '#4867AA', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><GrInstagram className="instagramColor rounded" style={{ fontSize: '28px', padding: '2px', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><ImLinkedin style={{ fontSize: '26px', color: '#0077B5', cursor: 'pointer' }} /></Link>
                                    <Link className="px-2" to="#"><AiFillTwitterSquare style={{ fontSize: '33px', color: '#1DA1F2', cursor: 'pointer' }} /></Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>







            <Footer />
        </div>
    );
};

export default Team;