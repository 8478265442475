import React from 'react';
import arrow from './../../../images/ion-arrow.png';

// CSS getting from the product.css file

const Service = ({serviceData}) => {
    const {title, img, des} = serviceData || {};
    return (
        <div className="col">
          <div className="card-main card">
            <div className='image-container'>
              <img src={img} className="card-image card-img-top" alt="..." />
            </div>
            <div className="card-body">
              <h5 className="card-title text-center">{title}</h5>
              <div className="extra-border">
                <p></p>
              </div>
              <p className="card-text text-center">{des.slice(0, 120)}...</p>
            </div>
            <div className="arrow-icon my-3 pe-4">
              <img src={arrow} alt='...' />
            </div>
          </div>
        </div>
    );
};

export default Service;