import React from "react";
// import Slider from "react-slick";

// import bg1 from "../../../images/bg1.jpg";
// import bg2 from "../../../images/bg2.png";
// import bg3 from "../../../images/bg3.png";
import accounting from '../../../images/accounting-soft-dark.jpg'
import "./Banner.css";

// import banneBgVideo from './../../../videos/banner_bg_video.mp4';
import Navigation from "../../Shared/Navigation/Navigation";
// import DemoVideo from '../../../videos/vd.mp4'
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
// import NavBar from "../../Shared/NavBar/NavBar";
// import { GiCheckMark } from 'react-icons/gi';
// import { FaQuoteLeft } from 'react-icons/fa';
// import { FaQuoteRight } from 'react-icons/fa';
// import problemLogo from './../../../images/problem.png';
// import { Link } from "react-router-dom";

const Banner = () => {
  // const allBanners = [
  //   {
  //     id: 1,
  //     title: "Financial & FinTech Solutions",
  //     description:
  //       "Digital Decoder Financial & FinTech Solutions help you create a superior customer experience, manufacture agile investment banking systems, proffer innovative financial solutions and optimize risk for enterprise financial management",
  //     img: "https://apsissolutions.com/wp-content/uploads/MicrosoftTeams-image-12.png",
  //     img1: "https://apsissolutions.com/wp-content/uploads/MicrosoftTeams-image-20.png",
  //   },
  //   {
  //     id: 2,
  //     title: "ERP Solution",
  //     description:
  //       "The integrated ecosystem & modular design of our ERP allows you to plug in modules that are relevant to your business model and allows easy integration as your organization evolves",
  //     img: "https://apsissolutions.com/wp-content/uploads/Future-Fit-Value-Creation.png",
  //     img1: "https://apsissolutions.com/wp-content/uploads/MicrosoftTeams-image-17.png",
  //   },
  //   {
  //     id: 3,
  //     title: "CRM & Consumer Solutions",
  //     description:
  //       "Build long lasting relationship with your customers with the tools of Apsis CRM and Consumer Solutions.",
  //     img: "https://apsissolutions.com/wp-content/uploads/slider/cache/7ac22359630267bd241b3391db37df24/MicrosoftTeams-image-21.jpg",
  //     img1: "https://apsissolutions.com/wp-content/uploads/MicrosoftTeams-image-11.png",
  //   },
  // ];


  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 2000,
  //   autoplay: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   arrows: false,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 1
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };

  return (
    <>
      <div className="banner-wrapper" style={{ 'marginTop': '92px' }}>
        {/* <ParticleBg></ParticleBg> */}
        {/* <video src={banneBgVideo} autoPlay loop muted></video> */}
        <div className="banner-contents">
          <div className="">
            <div className="banner-nav">
              <Navigation />
            </div>
          </div>
          <div className="container h-100 w-100 py-5 mt-3 align-items-center">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <div className="banner-section-wrap mb-4">
                  <div className="section-title-wrap">
                    <h1 className="section-title text-white mb-3">
                      Online Accounting Software,
                      <br />
                      Built for Your Business.
                    </h1>
                    <p className="section-desc text-white text-center">
                      Multi Currency Solution is an all-in-one management system that enables you to manage <br className="d-xl-block d-none" /> Currency, Banking,  Sales, Purchases, Journals, Accounting,  Assets, Database backup and many more.
                    </p>
                  </div>
                  <div className="banner-section-btns">
                    <Link className="btn-1 btn btn-primary me-2 text-uppercase btn-sm" to='/trial'>Get a 30-days free trial</Link>
                    <div className="btn-2 btn btn-danger text-uppercase btn-sm" onClick={() => (window.open("https://demo.mlcaccounting.techvaultltd.com/User/Login"))} >explore demo account</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-10">
                <div className="banner-video-a">
                  {/* <div className="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/xNRJwmlRBNU" title="YouTube video" allowfullscreen></iframe>
                  </div> */}
                  <div className="banner-video-player d-flex justify-content-center">
                    {/* <ReactPlayer className='video' url='https://youtu.be/L93hyPiltLA' controls /> */}
                    {/* <ReactPlayer className='video' url={DemoVideo} controls />*/} {/* <-- this line has some problem */}
                    <ReactPlayer
                    playing={true}
                      className='video'
                      light={<img src={accounting} alt='Thumbnail' />}
                      url='./vd.mp4'
                      controls />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-none d-md-block text-white p-4 rounded bannerContents">
              <div className="d-none d-md-block">
                <h2 className="mb-5">Transform your innovative ideas into <br /> your  custom business!</h2>
                <p>Digital Decoder Limited with its dedicated teams render high quality IT solutions with professional management services of excellence, quality assurance & on time turnkey automation.</p>
                <div className="my-2">
                  <GiCheckMark className="checkMark" /> <span className="ps-3 fs-6">We design and co-create a product with you.</span>
                </div>
                <div className="my-2">
                  <GiCheckMark className="checkMark" /> <span className="ps-3 fs-6">We manage and provide a professional dev team for you.</span>
                </div>
                <div className="my-2">
                  <GiCheckMark className="checkMark" /> <span className="ps-3 fs-6">We establish trust through partner-centric transparency.</span>
                </div>
                <div className="my-2">
                  <GiCheckMark className="checkMark" /> <span className="ps-3 fs-6">We manage the entire product development cycle.</span>
                </div>
              </div>
              <div className="mt-md-5 w-100">
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-lg-3 d-flex align-items-center text-success">
                    <p><GiCheckMark className="checkMark text-white" style={{ backgroundColor: '#0e89fb' }} /></p>
                    <p className="ms-3 text-white">Get Access to Top Developers</p>
                  </div>
                  <div className="col-md-6 col-lg-6 col-lg-3 d-flex align-items-center text-success">
                    <p><GiCheckMark className="checkMark text-white" style={{ backgroundColor: '#0e89fb' }} /></p>
                    <p className="ms-3 text-white">Get a Trustworthy Partner</p>
                  </div>
                  <div className="col-md-6 col-lg-6 col-lg-3 d-flex align-items-center text-success">
                    <p><GiCheckMark className="checkMark text-white" style={{ backgroundColor: '#0e89fb' }} /></p>
                    <p className="ms-3 text-white">Experienced IT Recruiters</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bannerSlider ps-5">
                <div className="w-100">
                  <Slider {...settings}>
                    <div className="text-white text-center">
                      <div className="d-flex align-items-center justify-content-center rounded slideItem one">
                        <div className="text-center ">
                          <div className="d-flex justify-content-center">
                            <img src={problemLogo} title="problem" width='120' height='110' alt="problem-sad" />
                          </div>
                          <p className="fs-4 my-0 text-uppercase fw-bold" style={{letterSpacing: '5px'}}>Problem !!!</p>
                          
                          <h2>Do you need any solutions?</h2>
                          <p><Link to="/services" className="text-white" style={{textDecoration: 'none'}}>We Provide...</Link></p>
                        </div>
                      </div>
                    </div>
                    <div className="text-white ">
          
                        <div className="d-flex align-items-center justify-content-center rounded slideItem two">
                          <div className="text-center">
                            <h2 className="text-start"><FaQuoteLeft className="quotation"/></h2>
                            <h4>Business Website</h4>
                            <h4>E-commerce Website</h4>
                            <h2 className="text-end"><FaQuoteRight className="quotation"/></h2>
                          </div>
                        </div>
                  
                    </div>
                    <div className="text-white">
                      <div className="d-flex align-items-center justify-content-center rounded slideItem three">
                        <div className="text-center">
                          <h2 className="text-start"><FaQuoteLeft className="quotation"/></h2>
                          <h4>ERP Solution</h4>
                          <h4>Accounting Solution</h4>
                          <h2 className="text-end"><FaQuoteRight className="quotation"/></h2>
                        </div>
                      </div>
                    </div>
                    <div className="text-white">
                      <div className="d-flex align-items-center justify-content-center rounded slideItem four">
                        <div className="text-center">
                          <h2 className="text-start"><FaQuoteLeft className="quotation"/></h2>
                          <h4>Human Resource Management</h4>
                          <h4>School Mangemenent</h4>
                          <h2 className="text-end"><FaQuoteRight className="quotation"/></h2>
                        </div>
                      </div>
                    </div>
                    <div className="text-white">
                      <div className="d-flex align-items-center justify-content-center rounded slideItem five">
                        <div className="text-center">
                          <h2 className="text-start"><FaQuoteLeft className="quotation"/></h2>
                          <h4>Hospital Management</h4>
                          <h4>Pharmacy Management</h4>
                          <h2 className="text-end"><FaQuoteRight className="quotation"/></h2>
                        </div>
                      </div>
                    </div>
                    <div className="text-white">
                      <div className="d-flex align-items-center justify-content-center rounded slideItem six">
                        <div className="text-center">
                          <h4>Any kinds of automation solution</h4>
                          <Link to="/contact"><button className="btn btn-info">Contact Us</button></Link>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
            </div> */}
          </div>
        </div>
      </div>

    </>
  );
};

export default Banner;