import React from "react";
import arrow from './../../../images/ion-arrow.png';
import './Products.css';


const Product = ({ productData }) => {
  const { title, img, des, url } = productData || {};

  const containerStyles = {
    backgroundImage: `url(${img})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    transition: 'background-position 3.5s linear',
    height:'200px',
  };


  return (
    <div className="">
      <div className="col" >
        <div className="card-main card" onClick={() => (window.open(url))}>
          <div className="image-container">
             {/* <img src={img} className="card-image" alt="..." /> */}
            <div className="hover-bg-img" style={containerStyles}>
              {/* Your content here */}
            </div>
          </div>
          <div className="card-body">
            <h5 className="card-title text-center">{title}</h5>
            <div className="extra-border">
              <p></p>
            </div>
            <p className="card-text text-center">{des.slice(0, 120)}...</p>
          </div>
          <div className="arrow-icon my-3 pe-4">
            <img src={arrow} alt='...' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
