import React from 'react';
import group from './../../../images/developmentModel/Group-14948.png';
import staffImage from './../../../images/developmentModel/staff-augmentation.jpg';
import './StaffAugmentation.css';

const StaffAugmentation = () => {
    return (
        <div className=''>
            <div className='row my-5'>
                <div className='col-md-7'>
                    <img className='my-3' src={group} alt='...'/>
                    <h2 className='text-start my-3'>Staff Augmentation</h2>
                    <p className='pe-5 me-3'>Fill in the gaps in your workforce by shaking hands with a group of talented engineers ready to take on your business challenges. Codup provides qualified and skilled software developers and engineers to perform tasks and generate a strong synergy of great minds working together for a preferable outcome.</p>
                </div>
                <div className='col-md-5'>
                    <img className='img-fluid w-100' src={staffImage} alt='...'/>
                </div>
            </div>
        </div>
    );
};

export default StaffAugmentation;