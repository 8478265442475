import React from 'react';
import { Fade  } from 'react-reveal';   // actually {fade, Reveal}
import Footer from '../Shared/Footer/Footer';
// import Navigation from '../Shared/Navigation/Navigation';
import './Contact.css';
import { ImLocation2 } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { FiPhoneCall } from 'react-icons/fi';
import contactImg from './../../images/contact-img.jpg';
import CommonNavigation from '../Shared/CommonNavigation/CommonNavigation';

const Contact = () => {
    return (
        <div>
            {/* <Navigation /> */}

            <CommonNavigation/>

            
            <div className="contact_bg d-flex justify-content-center align-items-center" style={{marginTop:'92px'}}>
                <Fade top>
                    <h2 className="text-light fw-bolder">Contact Us</h2>
                </Fade>
            </div>

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className='my-5'>
                            <form className='d-flex flex-column mx-5 py-3 border-0'>
                            <Fade left>
                                <h2 className='mb-5'>Get a free consultation now</h2>
                                <label className='mb-1' htmlFor="name">Name: <span className='text-danger'>*</span></label>
                                <input className='input_field px-2 py-2 mb-3' id='name' type="text" placeholder='Your name' required/>

                                <label className='mb-1' htmlFor="email">Email: <span className='text-danger'>*</span></label>
                                <input className='input_field px-2 py-2 mb-3' type="email" name="" id="email" placeholder='Your email' required/>

                                <label className='mb-1' htmlFor="message">Message: <span className='text-danger'>*</span></label>
                                <textarea className='input_field px-2 py-2 mb-3' name="" id="message" cols="30" rows="5" placeholder='Write here...' required></textarea>

                                <button className='submit_button mt-4 py-2'>Send Message</button>
                                </Fade>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <Fade right>
                            <div className='p-3'>
                                <img className='img-fluid' src={contactImg} alt='contact' />
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="phone m-3 my-5 p-lg-5 p-3" style={{boxShadow: '1px 1px 5px #0000004d'}}>
                            <Fade top>
                                <h3>Phone</h3>
                                <p className='text-muted'>Our customer care is open from Sun-Thu, <br /> 09:00am to 06:00pm</p>
                                <div>
                                    <FiPhoneCall className='phoneIcon  mb-2'/>
                                    <a className='phone_number px-3' href="tel:+9613661122">+9613661122</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="email m-3 my-5 p-lg-5 p-3" style={{boxShadow: '1px 1px 5px #0000004d'}}>
                            <Fade top>
                                <h3>Email</h3>
                                <p className='text-muted'>Our customer care is open from Sun-Thu, <br /> 09:00am to 06:00pm</p>
                                <div className=''>
                                    <MdEmail className='emailIcon mb-2'/>
                                    <a className='email_address px-3' href="mailto:info@digitaldecoderltd.com">info@digitaldecoderltd.com</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>


            <div className='container my-5'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 p-3'>
                        <div className='office-address p-2 border rounded shadow '>
                            <Fade top>
                                <h3 className='text-center py-4'>Bangladesh</h3>
                                <div className='text-center'>
                                    <ImLocation2 className='locationIcon'/>
                                </div>
                                <p className='text-center'>  E-9/6, East Tower <br /> Chaina Town, Nayapaltan.</p>
                                <div className='text-center'>
                                    <MdEmail className='emailIcon'/>
                                    <a className='text-decoration-none fs-6 px-3' href="mailto:info@digitaldecoderltd.com">info@digitaldecoderltd.com</a>
                                </div>
                                <div className='my-3 text-center'>
                                    <FiPhoneCall className='phoneIcon'/>
                                    <a className='phone_number px-3' href="tel:+9613661122">+9613661122</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 p-3'>
                        <div className='office-address p-2 border rounded shadow'>
                            <Fade top>
                                <h3 className=' text-center py-4'>Canada</h3>
                                <div className='text-center'>
                                    <ImLocation2 className='locationIcon'/>
                                </div>
                                <p className=' text-center'>225 Callen Dr, Fort McMurray, <br />  Alberta T9K 0X7, Canada</p>
                                <div className='text-center'>
                                    <MdEmail className='emailIcon'/>
                                    <a className='text-decoration-none  fs-6 px-3' href="mailto:info@digitaldecoderltd.com">info@digitaldecoderltd.com</a>
                                </div>
                                <div className='my-3 text-center'>
                                    <FiPhoneCall className='phoneIcon'/>
                                    <a className='phone_number px-3' href="tel:+1587284-9939">+1(587)284-9939</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 p-3'>
                        <div className='office-address p-2 border rounded shadow'>
                            <Fade top>
                                <h3 className=' text-center py-4'>Finland</h3>
                                <div className='text-center'>
                                    <ImLocation2 className='locationIcon'/>
                                </div>
                                <p className='text-center'>F 55,Kilonkallio 10, Espoo, <br />  Helsinki, Finland</p>
                                <div className='text-center'> 
                                    <MdEmail className='emailIcon'/>
                                    <a className='text-decoration-none fs-6 px-3' href="mailto:info@digitaldecoderltd.com">info@digitaldecoderltd.com</a>
                                </div>
                                <div className='my-3 text-center'>
                                    <FiPhoneCall className='phoneIcon'/>
                                    <a className='phone_number px-3' href="tel:+358468466200">+358468466200</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3 p-3'>
                        <div className=' office-address p-2 border rounded shadow'>
                            <Fade top>
                                <h3 className=' text-center py-4'>Canada</h3>
                                <div className='text-center'>
                                    <ImLocation2 className='locationIcon'/>
                                </div>
                                <p className=' text-center'>260 Panatella Dr Nw, Calgary, <br />  AB, Canada</p>
                                <div className='text-center'>
                                    <MdEmail className='emailIcon'/>
                                    <a className='text-decoration-none fs-6 px-3' href="mailto:info@digitaldecoderltd.com">info@digitaldecoderltd.com</a>
                                </div>
                                <div className='my-3 text-center'>
                                    <FiPhoneCall className='phoneIcon'/>
                                    <a className='phone_number px-3' href="tel:+1403863-9077">+1(403)863-9077</a>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default Contact;