import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import './DevelopmentModels.css';
import group from './../../images/developmentModel/Group-15010.png';

const DevelopmentModels = () => {
    return (
        <div className=''>
            <div className='developmentModels-wrapper my-5 py-5'>
                <div className='container'>
                    <h2 className='py-4'>Software Development Engagement Models</h2>

                    <div className='model-menu'>
                        <Link to = "/">Staff Augmentation</Link>
                        <Link to = "time">Time and Material</Link>
                        <Link to = "fixedPrice">Fixed Price</Link>
                        <Link to = "monthlyRetainer">Monthly Retainer</Link>
                    </div>
                    <Outlet/>
                </div>
            </div>
            <div className='container' >
                <div className='life-cycle'>
                    <img src={group} alt='...' />
                </div>
            </div>
        </div>
    );
};

export default DevelopmentModels;