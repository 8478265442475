// import cityBank from "./../images/clients/City-Bank-1.png";
// import robi from "./../images/clients/Robi.png";
// import asianpaints from "./../images/clients/Asian-Paints.png";
// import ific from "./../images/clients/IFIC-Logo.png";
// import beximco from "./../images/clients/bexcom.png";
// import navana from "./../images/clients/navana-group-logo-1.jpg";
// import ipdc from "./../images/clients/IPDC.png";
// import bengalMeal from "./../images/clients/pack-ribbon_outlined.png";
// import agami from "./../images/clients/Agami.png";
// import meridian from "./../images/clients/Meridian-Finance.png";
// import primeBank from "./../images/clients/Prime-Bank.png";
// import runner from "./../images/clients/runner.jpg";
// import marketAccess from "./../images/clients/Market-Access-Group.png";
// import srChemical from "./../images/clients/SR-Chemical-Industries-Ltd.png";
// import srParcel from "./../images/clients/SR-Parcel-Services-Ltd.png";
// import srTechno from "./../images/clients/SR-Techno-Trade-Ltd.png";
// import premierBank from "./../images/clients/Premier-Bank.png";
// import unique from "./../images/clients/Unique-Infoway-Ltd.png";
// import openUniversity from "./../images/clients/Bangladesh-Open-University.png";
// import easternMotors from "./../images/clients/Eastern-Motors-Ltd.png";
// import brtc from "./../images/clients/BD-Roads-Highway.png";
// import lafarge from "./../images/clients/Lafarge-Surma-Cement-Ltd.png";
// import iidfc from "./../images/clients/IIDFC-2.png";
// import uttaraFinance from "./../images/clients/Uttara-Finance-Investments-Ltd.jpg";
// import p from "./../images/clients/P.jpg";
// import qubee from "./../images/clients/Qubee.png";
// import bdMilitary from "./../images/clients/BD-Military-Academy.jpg";

// import rupalink from "./../images/clients/MicrosoftTeams-image-23.png";
// import telMax from "./../images/clients/telmax-600px-logo.png";
// import medela from "./../images/clients/medela-logo-vector.png";
// import isi from "./../images/clients/MicrosoftTeams-image-24.png";




// our clients 
import bdGov from './../images/client-logo/government-logo@2x.png';
import rcc from './../images/client-logo/rcc-logo.png';
import dubaiTrade from './../images/client-logo/dubai-trade.jpg';
import globalManPower from './../images/client-logo/global-manpower.png';
import bandarbanSchool from './../images/client-logo/bandarban.png';
import teleco from './../images/client-logo/TELCO-logo-PNG-05.png';
import alreeq from './../images/client-logo/al-areeq.png';
import rtr from './../images/client-logo/roomtoread.png';

export const ourClients = [
  `${bdGov}`,
  `${rcc}`,
  `${dubaiTrade}`,
  `${globalManPower}`,
  `${bandarbanSchool}`,
  `${teleco}`,
  `${alreeq}`,
  `${rtr}`,
]

// export const localClientsLogo = [
//   `${cityBank}`,
//   `${robi}`,
//   `${asianpaints}`,
//   `${ific}`,
//   `${beximco}`,
//   `${navana}`,
//   `${ipdc}`,
//   `${bengalMeal}`,
//   `${agami}`,
//   `${meridian}`,
//   `${primeBank}`,
//   `${runner}`,
//   `${marketAccess}`,
//   `${srChemical}`,
//   `${srParcel}`,
//   `${srTechno}`,
//   `${premierBank}`,
//   `${unique}`,
//   `${openUniversity}`,
//   `${easternMotors}`,
//   `${brtc}`,
//   `${lafarge}`,
//   `${iidfc}`,
//   `${uttaraFinance}`,
//   `${p}`,
//   `${qubee}`,
//   `${bdMilitary}`,
// ];

// export const internationalClientsLogo = [
//   `${rupalink}`,
//   `${telMax}`,
//   `${medela}`,
//   `${isi}`,
// ];





