import React, { useState } from "react";
import Slider from "react-slick";
import Footer from "../../Shared/Footer/Footer";
import Banner from "../Banner/Banner";
// import businessstrategyimage from "../../../images/businessstrategy.png";
// import certificateOneImg from "../../../images/certificate1.png";
// import certificateTwoImg from "../../../images/certificate2.png";
// import certificateThreeImg from "../../../images/certificate3.png";
// import arrow from "./../../../images/ion-arrow.png";

import "./Home.css";
import DevelopmentModels from "../../DevelopmentModels/DevelopmentModels";
import { productsData } from "../../../Data/productsData";
import Product from "../../Products/Product/Product";
import { NavLink } from "react-router-dom";
import { servicesData } from "../../../Data/servicesData";
import Service from "../../Services/Service/Service";
// import { RiNumber1 } from 'react-icons/ri';
// RiNumber1

import { ourClients } from "../../../Data/clientsLogo";

import send from '../../../images/svg/send-1.png';

import { MdOutlineDoubleArrow } from "react-icons/md";
// import { MdProductionQuantityLimits } from "react-icons/md";
import DevelopmentModelsForMobileTab from "../../DevelopmentModels/DevelopmentModelsForMobileTab";

import muscle from './../../../images/muscle.png';
import running from './../../../images/running.png';
import meeting from './../../../images/meeting.png';
import weight from './../../../images/weight.png';
import setting from './../../../images/setting.png';
import priceTag from './../../../images/priceTag.png';


const Home = () => {

  const [email, setEmail] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();

  


    // Perform any form validation before submitting the form data
     

    console.log(`Submitting form data: Email: ${email}`);
    // Send the form data to the server using an API or HTTP request
    // Reset the form fields
    setEmail('');
};


  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="container">{/* <Navigation /> */}</div>
      <div>
        <Banner />
      </div>

      <section className="business-strategy my-5">
        <div className="container">
          <h2 className="text-center my-5">Our Bussiness Strategy</h2>
          <div className="row">
            <div className="col-md-6 col-lg-4  mt-3" >
              <div className="border rounded">
                <div className="w-100 py-2 px-3" style={{ backgroundColor: '#53CAFE', color: 'white', height: '280px' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p > Step <br /> <span className="fw-bolder m-0 p-0 textShadow" style={{ fontSize: '60px' }}>1</span> </p>
                    <img src={muscle} style={{ width: '80px', height: '80px' }} alt="" />
                  </div>

                  <h3 className="">Business Outcome Focus</h3>
                  <p>Digital Decoder always believes in value generation for Customer using right Technology</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4  mt-3">
              <div className="d-flex border rounded">
                <div className="w-100 py-2 px-3" style={{ backgroundColor: '#019ADD', color: 'white', height: '280px' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p > Step <br /> <span className="fw-bolder m-0 p-0 textShadow" style={{ fontSize: '60px' }}>2</span> </p>
                    <img src={meeting} style={{ width: '80px', height: '80px' }} alt="" />
                  </div>

                  <h3 className="">Innovation & Digital</h3>
                  <p>Digital Decoder has dedicated practice for Innovation and Digital to offer solutions using moderns technologies</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4  mt-3">
              <div className="d-flex border rounded">
                <div className="w-100 py-2 px-3" style={{ backgroundColor: '#588FA5', color: 'white', height: '280px' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p> Step <br /> <span className="fw-bolder m-0 p-0 textShadow" style={{ fontSize: '60px' }}>3</span> </p>
                    <img src={setting} style={{ width: '80px', height: '80px' }} alt="" />
                  </div>

                  <h3 className="">Speed to Market</h3>
                  <p>Digital Decoder always believes faster turnaround using an agile approach to show early value</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4  mt-3">
              <div className="d-flex border rounded">
                <div className="w-100 py-2 px-3" style={{ backgroundColor: '#1F497D', color: 'white', height: '280px' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p > Step <br /> <span className="fw-bolder m-0 p-0 textShadow" style={{ fontSize: '60px' }}>4</span> </p>
                    <img src={running} style={{ width: '80px', height: '80px' }} alt="" />
                  </div>

                  <h3 className="">Data and Analytics</h3>
                  <p>Digital Decoder works with Customers and transform data into business insight using data and BI tools</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4  mt-3">
              <div className="d-flex border rounded">
                <div className="w-100 py-2 px-3" style={{ backgroundColor: '#0097AA', color: 'white', height: '280px' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p > Step <br /> <span className="fw-bolder m-0 p-0 textShadow" style={{ fontSize: '60px' }}>5</span> </p>
                    <img src={weight} style={{ width: '80px', height: '80px' }} alt="" />
                  </div>

                  <h3 className="">Consistency & Uniformity</h3>
                  <p>Digital Decoder ensures consistent & uniform service throughout the entire development process</p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4  mt-3">
              <div className="d-flex border rounded">
                <div className="w-100 py-2 px-3" style={{ backgroundColor: '#A7CCDF', color: 'white', height: '280px' }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <p > Step <br /> <span className="fw-bolder m-0 p-0 textShadow" style={{ fontSize: '60px' }}>6</span> </p>
                    <img src={priceTag} style={{ width: '80px', height: '80px' }} alt="" />
                  </div>

                  <h3 className="">Competitive price</h3>
                  <p>Digital Decoder provides a competitive & logical price of its products and services</p>
                </div>
              </div>
            </div>



            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="bussiness-contain w-100">
                <img className="img-fluid" src={businessstrategyimage} alt="" />
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="products productsBG my-5" >
        <h2 className="text-center mb-5">Our Products</h2>
        {/* <div className="text-center">
          <MdProductionQuantityLimits className="titleIcon"/>
        </div> */}
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
            {productsData.slice(0, 3).map((productData) => (
              <Product key={Math.random()} productData={productData}></Product>
            ))}
          </div>
          <p className="py-4 text-center">
            <NavLink to="products" className="load-more border py-2 px-3">
              Load More <MdOutlineDoubleArrow />
            </NavLink>
          </p>
        </div>
      </section>

      <section className="services container">
        <h2 className="text-center my-5">Our Services</h2>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
          {servicesData.slice(0, 3).map((serviceData) => (
            <Service key={Math.random()} serviceData={serviceData}></Service>
          ))}
        </div>
        <p className="my-4 text-center">
          <NavLink to="services" className="load-more border py-2 px-3">
            Load More <MdOutlineDoubleArrow />
          </NavLink>
        </p>
      </section>

      <section >
        <div className="desktop-view d-none d-lg-block">
          <DevelopmentModels />
        </div>
        <div className="d-lg-none font-with-bg-color py-5">
          <DevelopmentModelsForMobileTab />
        </div>
      </section>

      {/* <section className="affiliation-certificate">
        <div className="container">
          <h2 className="text-center my-5">Affiliation & Alliances</h2>
          <div className="row">
            <div className="col-4">
              <div className="certificate-container">
                <img
                  className="img-fluid my-4 mx-5"
                  src={certificateOneImg}
                  alt="..."
                  style={{ width: "250px", height: "80px" }}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="certificate-container">
                <img
                  className="img-fluid"
                  src={certificateTwoImg}
                  alt="..."
                  style={{
                    width: "150px",
                    height: "157px",
                    marginLeft: "85px",
                  }}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="certificate-container">
                <img
                  className="img-fluid"
                  src={certificateThreeImg}
                  alt="..."
                  style={{
                    width: "150px",
                    height: "148px",
                    marginLeft: "85px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="clients-slider my-5">
        <div className="container">
          <h2 className="text-center my-5">Our Amazing Clients</h2>

          {/* slider  */}
          <div>
            <Slider {...settings}>
              {ourClients.map((logo) => (
                <div>
                  <div className="client-container">
                    <img className="" src={logo} alt="..." />
                  </div>
                </div>
              ))}
            </Slider>

            {/* slider end  */}
          </div>
        </div>
      </section>

      <section className="newsletter">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
              <h3 className="text-md-end text-center mb-md-0 mb-3">Subscribe Now</h3>
            </div>
            <div className="col-xl-6 col-lg-9 col-md-9 col-sm-12 col-12">
              <form className="border-0" onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Your Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="input-group-btn">
                    <button className="btn d-none d-md-block" type="submit">
                      Subscribe Now
                    </button>
                    <button className="text-end d-md-none d-block" type="submit">
                      <img style={{ maxWidth: '40%', display: 'block', margin: '0 auto', filter: 'brightness(0) invert(1)' }} src={send} alt="..." />
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
