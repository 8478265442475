import React from "react";
import Footer from "../Shared/Footer/Footer";
// import Navigation from "../Shared/Navigation/Navigation";
import "./Products.css";

import Fade from "react-reveal/Fade";
import { productsData } from "../../Data/productsData";
import Product from "./Product/Product";
import CommonNavigation from "../Shared/CommonNavigation/CommonNavigation";

const Products = () => {
  return (
    <div>
        {/* <Navigation /> */}

        <CommonNavigation/>

      <div className="product_bg d-flex justify-content-center align-items-center" style={{marginTop:'92px'}}>
        <Fade top>
          <h2 className="text-light fw-bolder text-capitalize">Our Products</h2>
        </Fade>

      </div>
      <div className="container my-5">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
          {
            productsData.map(productData => <Product key={Math.random()} productData = {productData}></Product>)
          }
        </div>
      </div>

     
      <Footer />
    </div>
  );
};

export default Products;
