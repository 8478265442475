import webDevelopment from './../images/servicesImage/webdevelopment.jpg';
import webDesign from './../images/servicesImage/web-design.jpg';
import eCommerce from './../images/servicesImage/e-commerce-development.jpg';
import mobileApp from './../images/servicesImage/mobile-app-development.jpg';
import cms from './../images/servicesImage/cms-development.jpg';
import lms from './../images/servicesImage/lms.png';
import digitalSeo from './../images/servicesImage/digital-markign-and-seo.jpg';


export const servicesData = [
  {
    id: 1,
    title: "Web Development",
    img: `${webDevelopment}`,
    des: 'We believe “Customer satisfaction is our main profit”. We always try to develop innovative and creative products and services for our valuable customers. We are committed to providing quality service to our valuable clients. We are super experts in software development, cloud-based software, desktop-based software, and on-demand customer.',
  },
  {
    id: 2,
    title: "Web Design",
    img: `${webDesign}`,
    des: "We have enough qualified teams for web design. We keep a close eye on the latest market trend for web design and always try to be top service very sincerely and appropriately to our clients.",
  },
  {
    id: 3,
    title: "E-Commerce Development",
    img: `${eCommerce}`,
    des: "We have quality multi-vendor, single vendor products for e-commerce. As we have a dedicated team for e-commerce development, we focus on our client requirements to fulfill the optimal level of satisfaction. We are super client oriented about e-commerce development.",
  },
  {
    id: 4,
    title: "Mobile Application Development",
    img: `${mobileApp}`,
    des: "We provide a range of mobile application development services including custom mobile development on IOS or Android, Progressive web app and cross-platform app development, Design UI/UX service, Mobile app porting, App consulting and prototyping, Cloud consulting, Microservice Architecture for mobile app development.",
  },
  {
    id: 5,
    title: "CMS Development",
    img: `${cms}`,
    des: "We provide CMS for any specific requirements for the customer about solving the business-related issue. CMS comes in all shapes and sizes with its ownset of features and benefits. CMS is tailored to e-commerce sites with features of pricing and online store functionality as well.",
  },
  {
    id: 6,
    title: "LMS Development",
    img: `${lms}`,
    des: "We provide LMS for any specific requirements for the customer about solving the business-related issue. CMS comes in all shapes and sizes with its ownset of features and benefits. CMS is tailored to e-commerce sites with features of pricing and online store functionality as well.",
  },
  {
    id: 7,
    title: "Digital Marketing and SEO",
    img: `${digitalSeo}`,
    des: "Digital Marketing gives businesses a platform that streamlines the creative and content adaption process to help brands conquer personalization through expertise in the Digital Marketing field. Our services include Planning and Strategy, Organic SEO Operation, Google advertising, Facebook, Instagram, and LinkedIn Ad Management, Company Branding, Graphics Design & Video Editing, and a range of other digital marketing services and SEO that meets 360-degree digital marketing solution.",
  },
];