import React from 'react';
import './Time.css';
import time from './../../../images/developmentModel/Time-and-material-1.png'
import time2 from './../../../images/developmentModel/Time-and-material.png'

const Time = () => {
    return (
        <div className=''>
            <div className='row my-5'>
                <div className='col-md-7'>
                    <img className='my-3' src={time} alt='...'/>
                    <h2 className='text-start my-3'>Time and Material</h2>
                    <p className='pe-5 me-3'>The time and material outsourcing model is an hourly model in which you are given an estimate of the time in hours required for the development of a project sprint. The actual price estimate varies depending on which resource will be allocated for a particular sprint, as you pay only for the hours spent.</p>
                </div>
                <div className='col-md-5'>
                    <img className='img-fluid w-100' src={time2} alt='...'/>
                </div>
            </div>
        </div>
    );
};

export default Time;