import React from "react";
// import { VacancyData } from "../../Data/VacancyData";
import Footer from "../Shared/Footer/Footer";
// import Navigation from "../Shared/Navigation/Navigation";
// import { IoIosImages } from "react-icons/io";
import "./Career.css";
import Fade from "react-reveal/Fade";
import CommonNavigation from "../Shared/CommonNavigation/CommonNavigation";
// IoIosImages

const Career = () => {
  return (
    <div>
      {/* <Navigation /> */}
      <CommonNavigation/>

      <div className="career_bg d-flex justify-content-center align-items-center" style={{marginTop:'92px'}}>
        <Fade top>
          <h2 className="text-light fw-bolder">Career</h2>
        </Fade>
      </div>

      <div className="container">

      </div>

      <Footer />
    </div>
  );
};

export default Career;