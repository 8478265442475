import React from 'react';

const DisplayClientsLogo = ({logo}) => {
    return (
        <div className="col text-center">
              <div className="col py-3 card d-flex align-items-center justify-content-center overflow-hidden">
                <div >
                  <img
                    src={logo}
                    className="image_style img-fluid p-2 w-100 mb-3 box up-down"
                    alt="..."
                    style={{
                      height: "100px",
                      width: "200px",
                    }}
                  />
                </div>
              </div>
            </div>
    );
};

export default DisplayClientsLogo;