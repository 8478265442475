import React from 'react';
import fixedPrice from './../../../images/developmentModel/Fixed-price-1.png';
import fixedPrice2 from './../../../images/developmentModel/Fixed-Price.png';
import './FixedPrice.css';

const FixedPrice = () => {
    return (
        <div className=''>
            <div className='row my-5'>
                <div className='col-md-7'>
                    <img className='my-3' src={fixedPrice} alt='...'/>
                    <h2 className='text-start my-3'>Fixed Price
</h2>
                    <p className='pe-5 me-3'>If you understand project requirements are clear from the get go, the Fixed-priced outsourcing model is for you. We provide you with a fixed scope and a fixed quote and ensure that your project is delivered within budget, timeline, and scope.</p>
                </div>
                <div className='col-md-5'>
                    <img className='img-fluid w-100' src={fixedPrice2} alt='...'/>
                </div>
            </div>
        </div>
    );
};

export default FixedPrice;