import "./App.css";
import Home from "./pages/Home/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Products from "./pages/Products/Products";
import Services from "./pages/Services/Services";
import ClientsPortfolio from "./pages/ClientsPortfolio/ClientsPortfolio";
import About from "./pages/About/About";
import Career from "./pages/Career/Career";
import StaffAugmentation from "./pages/DevelopmentModels/StaffAugmentation/StaffAugmentation";
import MonthlyRetainer from "./pages/DevelopmentModels/MonthlyRetainer/MonthlyRetainer";
import Time from "./pages/DevelopmentModels/Time/Time";
import FixedPrice from "./pages/DevelopmentModels/FixedPrice/FixedPrice";
import Contact from "./pages/Contact/Contact";
import ParticleBg from "./pages/Home/ParticleBg/ParticleBg";
import Team from "./pages/Team/Team";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Hire from "./pages/Hire/Hire";
import Trial from "./pages/Trial/Trial";

function App() {
  return (
    <>
    
      <BrowserRouter>
        <ParticleBg />
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/" element={<StaffAugmentation />} />
            <Route path="/staff" element={<StaffAugmentation />} />
            <Route path="/time" element={<Time />} />
            <Route path="/fixedPrice" element={<FixedPrice />} />
            <Route path="/monthlyRetainer" element={<MonthlyRetainer />} />
          </Route>
          <Route path="/home" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/clientsPortfolio" element={<ClientsPortfolio />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/team" element={<Team />} />
          <Route path="/hire" element={<Hire />} />
          <Route path="/trial" element={<Trial />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
