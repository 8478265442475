import React from "react";
import group from './../../images/developmentModel/Group-14948.png';
import staffImage from './../../images/developmentModel/staff-augmentation.jpg';

import time from './../../images/developmentModel/Time-and-material-1.png'
import time2 from './../../images/developmentModel/Time-and-material.png'

import fixedPrice from './../../images/developmentModel/Fixed-price-1.png';
import fixedPrice2 from './../../images/developmentModel/Fixed-Price.png';

import monthlyRetainer from './../../images/developmentModel/Monthly-retainer-1.png';
import monthlyRetainer2 from './../../images/developmentModel/Monthly-retainer.png';

const DevelopmentModelsForMobileTab = () => {
  return (
    <div className="container">
      <h3 className="text-center text-light  pb-4">Software Development Engagement Models</h3>
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item" >
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed font-with-bg-color"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              Staff Augmentation
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body font-with-bg-color">
              <div className=''>
                <img className='my-3' src={group} alt='...' />
                <h2 className='text-start my-3'>Staff Augmentation</h2>
                <p className=''>Fill in the gaps in your workforce by shaking hands with a group of talented engineers ready to take on your business challenges. Codup provides qualified and skilled software developers and engineers to perform tasks and generate a strong synergy of great minds working together for a preferable outcome.</p>
              </div>
              <div className=''>
                <img className='img-fluid w-100' src={staffImage} alt='...' />
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingTwo">
            <button
              className="accordion-button collapsed font-with-bg-color"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              Time and Material
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body font-with-bg-color">
              <div className=''>
                <img className='my-3' src={time} alt='...' />
                <h2 className='text-start my-3'>Time and Material</h2>
                <p className=''>The time and material outsourcing model is an hourly model in which you are given an estimate of the time in hours required for the development of a project sprint. The actual price estimate varies depending on which resource will be allocated for a particular sprint, as you pay only for the hours spent.</p>
              </div>
              <div className=''>
                <img className='img-fluid w-100' src={time2} alt='...' />
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed font-with-bg-color"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              Fixed Price
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body font-with-bg-color">
              <div className=''>
                <img className='my-3' src={fixedPrice} alt='...' />
                <h2 className='text-start my-3'>Fixed Price </h2>
                <p className=''>If you understand project requirements are clear from the get go, the Fixed-priced outsourcing model is for you. We provide you with a fixed scope and a fixed quote and ensure that your project is delivered within budget, timeline, and scope.</p>
              </div>
              <div className=''>
                <img className='img-fluid w-100' src={fixedPrice2} alt='...' />
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingFour">
            <button
              className="accordion-button collapsed font-with-bg-color"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              Monthly Retainer
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingFour"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body font-with-bg-color">
              <div className=''>
                <img className='my-3' src={monthlyRetainer} alt='...' />
                <h2 className='text-start my-3'>Monthly Retainer</h2>
                <p className=''>The monthly retainer model gives the client freedom to purchase hours of the outsourced resource. Retainer contracts are based on an hourly rate of the outsourced personnel and are paid for a specific number of hours they are retained for in a month.</p>
              </div>
              <div className=''>
                <img className='img-fluid w-100' src={monthlyRetainer2} alt='...' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentModelsForMobileTab;
