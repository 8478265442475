import React from "react";
// import Navigation from "../Shared/Navigation/Navigation";
import "./Services.css";
// import managedService from "./../../images/Managed-Services.png";
import Footer from "../Shared/Footer/Footer";
import Fade from "react-reveal/Fade";
import { servicesData } from "../../Data/servicesData";
import Service from "./Service/Service";
import CommonNavigation from "../Shared/CommonNavigation/CommonNavigation";

const Services = () => {
  return (
    <div>
      {/* <Navigation /> */}

      <CommonNavigation/>

      <div className="service_bg d-flex justify-content-center align-items-center" style={{marginTop:'92px'}}>
        <Fade top>
          <h2 className="text-light fw-bolder text-capitalize">Our Services</h2>
        </Fade>
      </div>
      
      <div className="container my-5">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
          {
            servicesData.map(serviceData => <Service key={Math.random()} serviceData = {serviceData}></Service>)
          }
        </div>
        
      </div>
     
      
      <Footer />
    </div>
  );
};

export default Services;
