import React from "react";
import Footer from "../Shared/Footer/Footer";
// import Navigation from "../Shared/Navigation/Navigation";
import "./ClientsPortfolio.css";
import {ourClients} from "../../Data/clientsLogo";
import DisplayClientsLogo from "./DisplayClientsLogo/DisplayClientsLogo";
import Fade from "react-reveal/Fade";
import CommonNavigation from "../Shared/CommonNavigation/CommonNavigation";

const ClientsPortfolio = () => {
  return (
    <div>
      {/* <Navigation /> */}

      <CommonNavigation/>

      <div className="clients_portfolio_bg d-flex justify-content-center align-items-center" style={{marginTop:'92px'}}>
        <Fade top>
          <h2 className="text-light fw-bolder">Clients Portfolio</h2>
        </Fade>
      </div>

      <div className="container">
        <Fade top>
          <h4
            className="fw-bolder fs-3 text-center my-5"
            style={{ color: "#13007B" }}
          >
            Our Clients
          </h4>
        </Fade>

        {/* our clients  */}
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 d-flex justify-content-center align-items-center g-4 mb-5">
          {ourClients.map((logo) => (
            <DisplayClientsLogo logo={logo} key={Math.random()} />
          ))}
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default ClientsPortfolio;
