// import productOne from './../images/product1.jpg';
import fileConverting from './../images/productsImage/File-COnversion.jpg';
// import fileConverting from './../images/accounting-soft-light.jpg';
// import accountingSoft from './../images/productsImage/Accounting-software.jpg';
import accountingSoft from './../images/accounting-soft-light.jpg';
// import accountingSoft from './../images/accounting-soft-dark.jpg';
// import hospitalManagement from './../images/productsImage/hospital-management-system.jpg';
import hospitalManagement from './../images/hospital-management.jpg';
// import institutionMangement from './../images/productsImage/Institute-Management-1.png';
import institutionMangement from './../images/college-management.jpg';
// import SFManagement from './../images/productsImage/Digital-SF-Mangement-system.jpg';
import documentsClearing from './../images/productsImage/DOCUMENTS-Clearing.png';
// import pointOfSale from './../images/productsImage/PoS-Management-system.png';
import tourTravels from './../images/pickvisa.jpg';
import ecommerceWebsite from './../images/ecommerce.jpg';
import lmsWebsite from './../images/lms.png';
// import tourTravels from './../images/productsImage/Tour-and-travel-management-system.jpg';
// import parkingManagement from './../images/productsImage/Parking-management-system.jpg';

export const productsData = [


    {
        id: 1,
        title: "Accounting Software",
        img: `${accountingSoft}`,
        url: 'https://demo.mlcaccounting.techvaultltd.com/User/Login',
        des: "Our accounting software is a powerful tool designed to help individuals and businesses manage their finances with ease. With features such as automated bookkeeping, bank reconciliation, and financial reporting, you can take control of your finances and make informed decisions. Whether you're a small business owner or an individual looking to manage your personal finances, our accounting software is the perfect solution.",
        livelink: "https://demo.accounting.techvaultltd.com/User/Login?ReturnUrl=%2f"
    },
    {
        id: 2,
        title: "Ecommerce Website",
        img: `${ecommerceWebsite}`,
        url: 'https://ecommerce.techvaultltd.com/',
        des: "Our ecommerce website is the ultimate destination for all your online shopping needs. With a user-friendly interface and a wide range of products, you can browse and purchase items from the comfort of your own home. Our secure payment gateway ensures that your transactions are safe and reliable. With fast shipping and excellent customer service, we are committed to providing you with an exceptional shopping experience.",
    },
    {
        id: 3,
        title: "College Management",
        img: `${institutionMangement}`,
        url: 'https://institutionamanagement.techvaultltd.com/',
        des: "Our college management system is a comprehensive tool designed to help educational institutions manage their operations effectively. From student enrollment and attendance tracking to course management and exam scheduling, our system streamlines all aspects of the college management. With features such as student performance tracking, financial management, and communication tools, our college management system is an essential tool for any educational institution looking to improve their operations.",
    },
    {
        id: 4,
        title: "Hospital Management",
        img: `${hospitalManagement}`,
        url: 'https://hms.taurisjapan-ai.com/Account/Login?ReturnUrl=%2F',
        des: "Our hospital management software is a powerful tool designed to help healthcare providers manage their operations effectively. With features such as patient management, appointment scheduling, and electronic medical records, our software streamlines all aspects of hospital management. Our system is user-friendly and customizable, ensuring that it meets the unique needs of your healthcare facility.",
    },

    // {
    //     id: 5,
    //     title: "SF Management",
    //     img: `${SFManagement}`,
    //     des: "SF Management system is the process by which general people get their government service from their region. Government authority can monitor all  services  they  are provided by the government to the citizens ",
    // },
    {
        id: 5,
        title: "Documents Clearing",
        img: `${documentsClearing}`,
        url: '',
        des: "This product is generating Dubai local area bulk documents. Users can purchase services from the Dubai government and make the package for their buyers. Users also can generate an invoice for a particular buyer against packages.",
        livelink: "https://techvaultltd.com/User/Login?ReturnUrl=%2f"
    },
    {
        id: 6,
        title: "File Converting",
        img: `${fileConverting}`,
        url: 'https://demo.file.techvaultltd.com/Users/Login',
        des: " File conversion is used for Dubai Trade HS Code-related problem-solving. After solving the HS code problem user can easily upload bulk data. This product is used for super time-consuming issues. User Approximately 6 hours saved per day for uploading Dubai Trade bulk data for this system.",
    },
    // {
    //     id: 7,
    //     title: "Point of Sale",
    //     img: `${pointOfSale}`,
    //     des: " A point of sale, or POS, is a system where users can manage every kind of sales and inventory of a shop. Users can generate sales reports, stock report inventory systems, etc.",
    // },
    // {
    //     id: 8,
    //     title: "Service Top",
    //     img: `${productOne}`,
    //     des: " All kinds of services which general people need for their daily life. We have enough good quality experienced people to give this kind of service online or offline.",
    // },
    // {
    //     id: 9,
    //     title: "Dukan Sheba",
    //     img: `${productOne}`,
    //     des: " Dukan Sheba is a management information system (MIS) for Retailers who want to keep track of their profit and stock levels without the need for complex setups.",
    // },
    {
        id: 7,
        title: "Tour and Travels",
        img: `${tourTravels}`,
        url: 'https://demo.travels.techvaultltd.com/User/Login',
        des: "Our tour and travel services offer a wide range of travel options to destinations around the world. Whether you're looking for a romantic getaway, a family vacation, or a corporate retreat, our experienced travel agents can help you plan the perfect trip. With personalized itineraries and expert guidance, we are committed to providing you with an unforgettable travel experience.",
        livelink: "https://demo.travels.techvaultltd.com/User/Login"
    },
    {
        id: 8,
        title: "Learning Management System",
        img: `${lmsWebsite}`,
        url: 'https://lms.techvaultltd.com/',
        des: "Learning Management System",
        livelink: "https://lms.techvaultltd.com/"
    },

    // {
    //     id: 11,
    //     title: "Amader Rajshahi",
    //     img: `${productOne}`,
    //     des: "",
    // },
    // {
    //     id: 12,
    //     title: "Parking Management",
    //     img: `${parkingManagement}`,
    //     des: "A parking management system automates a vehicle parking system. It optimizes all kinds of parking spaces and makes processes by scanning the number plate of a vehicle efficiently. This management system is used for dynamic real-time vehicle parking. Users will get all kinds of information such as vehicle & slot counts, available slots display, reserved parking, pay-and-park options, easy payments, reports, and a host of other features.",
    // },
    // {
    //     id: 13,
    //     title: "HRM Management",
    //     img: `${productOne}`,
    //     des: The human resource management system is used for all kinds of official staff issues monitoring at a glance all employee issues and maintaining a workforce of companies or organizations.",
    // },
    // {
    //     id: 12,
    //     title: "Inventory Management",
    //     img: `${productOne}`,
    //     des: " An Inventory management system is a process by which organization or companies track their goods, purchasing raw materials to production and sales of products system.",
    // }

]