import React, { useState } from 'react'
import Footer from "../Shared/Footer/Footer";
import CommonNavigation from '../Shared/CommonNavigation/CommonNavigation';
import { Fade } from 'react-reveal';
import './Hire.css';

const Hire = () => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [experience, setExperience] = useState('');
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('pdfFile', file);


        // Perform any form validation before submitting the form data
        var filename = file.name;
        console.log('fiel name : ' + filename);

        console.log(`Submitting form data: Name: ${name}, 
        Number: ${number}, 
        Address: ${address}, 
        Email: ${email},
        Experience: ${experience},
        CV: ${file}, 
        Message: ${message}`);
        // Send the form data to the server using an API or HTTP request
        // Reset the form fields
        setName('');
        setNumber('');
        setAddress('');
        setEmail('');
        setExperience('');
        setFile('');
        setMessage('');
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    return (
        <>
            <div><CommonNavigation /></div>
            <div className="product_bg d-flex justify-content-center align-items-center" style={{ marginTop: '92px' }}>
                <Fade top>
                    <h2 className="text-light fw-bolder text-capitalize">Our Team</h2>
                </Fade>
            </div>
            <div className="hire-area">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="row reg-form-wrapper justify-content-center">
                                <div className="col-12">
                                    <div className="section-title-join text-center">
                                        <h1>Want to join our team?</h1>
                                    </div>
                                </div>
                                <form className="row border-0" onSubmit={handleSubmit}>
                                    <div className="col-md-6">
                                        <div className='form-input-group'>
                                            <label htmlFor="name">Name: <span className='text-danger'>*</span></label>
                                            <input className='form-control' type="name" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-input-group'>
                                            <label htmlFor="number">Number: <span className='text-danger'>*</span></label>
                                            <input className='form-control' type="text" id="number" name="number" value={number} onChange={(e) => setNumber(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-input-group'>
                                            <label htmlFor="address">Address: <span className='text-danger'>*</span></label>
                                            <input className='form-control' type="address" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-input-group'>
                                            <label htmlFor="email">Email: <span className='text-danger'>*</span></label>
                                            <input className='form-control' type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-input-group'>
                                            <label htmlFor="experience">Experience: <span className='text-danger'>*</span></label>
                                            <input className='form-control' type="number" id="experience" name="experience" value={experience} onChange={(e) => setExperience(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='form-input-group'>
                                            <label htmlFor="pdfFile">Upload CV: <span className='text-danger'>*</span> (PDF only)</label>
                                            <input className='form-control' type="file" id="pdfFile" name="pdfFile" accept="application/pdf" onChange={handleFileChange} required />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className='form-input-group'>
                                            <label htmlFor="message">Describe Yourself: <span className='text-danger'>*</span></label>
                                            <textarea className='form-control' rows={6} id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 login-box">
                                        <button className='btn-submit w-100' type="submit">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div> <Footer /></div>
        </>
    )
}

export default Hire