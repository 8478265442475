import React, { useState } from 'react'
import Footer from "../Shared/Footer/Footer";
import CommonNavigation from '../Shared/CommonNavigation/CommonNavigation';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import axios from "axios";

const Trial = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contactperson, setContactPerson] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        axios
          //.post(`https://localhost:44326/Home/SendAccountingEmail?company=${name}&contact=${contactperson}&add=${address}&mobile=${mobile}&email=${email}`)
          .post(`https://demo.mlcaccounting.techvaultltd.com/Home/SendAccountingEmail?company=${name}&contact=${contactperson}&add=${address}&mobile=${mobile}&email=${email}`)
          .then((response) => {
            console.log(response);
              setSuccessMessage('Thank you for signing up for our 30-days free trial!, Our Team will contact with you soon, Please wait for a moment.');
          })
          .catch((err) => console.error(err));

        setName('');
        setEmail('');
        setContactPerson('');
        setMobile('');
        setAddress('');
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (event) => {
        setIsChecked(event.target.checked);
    };


    return (
        <>
            <CommonNavigation />
            <div className="contact_bg d-flex justify-content-center align-items-center" style={{ marginTop: '92px' }}>
                <Fade top>
                    <h2 className="text-light fw-bolder">Premium Plan</h2>
                </Fade>
            </div>


            <div className="hire-area">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        {/* <div className="col-lg-2"></div> */}
                        <div className="col-xl-8 col-lg-10">
                            <div className="row reg-form-wrapper justify-content-center">
                                <div className="col-12">
                                    <div className="section-title-join text-center">
                                        <h2>Get a 30-days free trial</h2>
                                    </div>
                                </div>
                                <form className='row border-0' onSubmit={handleSubmit}>
                                    {successMessage ? (
                                        <p className="success-message text-white">{successMessage}</p>
                                    ) : (
                                        <>
                                            <div className="col-xl-6">
                                                <div className="form-input-group">
                                                    <label htmlFor="name">Company Name: <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Enter your Company Name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-input-group">
                                                    <label htmlFor="email">Email: <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='form-control'
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Enter your email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-input-group">
                                                    <label htmlFor="contactperson">Contact Person: <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        id="contactperson"
                                                        name="contactperson"
                                                        placeholder="Enter Contact Person Name"
                                                        value={contactperson}
                                                        onChange={(e) => setContactPerson(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="form-input-group">
                                                    <label htmlFor="mobile">Mobile Number: <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        id="mobile"
                                                        name="mobile"
                                                        placeholder="Enter your Mobile Number"
                                                        value={mobile}
                                                        onChange={(e) => setMobile(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="form-input-group">
                                                    <label htmlFor="address">Address: <span className='text-danger'>*</span></label>
                                                    <input
                                                        className='form-control'
                                                        type="text"
                                                        id="address"
                                                        name="address"
                                                        placeholder="Enter your Address"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <p>
                                                    <input
                                                        id='confirmation'
                                                        type='checkbox'
                                                        checked={isChecked}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <label htmlFor="confirmation" className='ms-2 text-white'>
                                                        I agree to the <Link to='/terms'>Terms of Service</Link> and <Link to='/privacy'>Privacy Policy</Link>.
                                                    </label>
                                                </p>
                                            </div>
                                            <div className="col-lg-3 col-md-4 login-box">
                                                <button className='btn-submit w-100' type="submit">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    Submit
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </div>

                        </div>
                        {/* <div className="col-lg-2"></div> */}
                    </div>
                </div>
            </div>



            <div><Footer /></div>
        </>
    )
}

export default Trial