import React from "react";
import Footer from "../Shared/Footer/Footer";
// import Navigation from "../Shared/Navigation/Navigation";
import "./About.css";
// import { CgEditBlackPoint } from "react-icons/cg";
// import slide from "./../../images/slide-01.jpg";
import Fade from "react-reveal/Fade";
import missionImg from './../../images/missionVission/mission-2.webp';
import visionImg from './../../images/missionVission/vission-2.webp';
import CommonNavigation from "../Shared/CommonNavigation/CommonNavigation";

const About = () => {
  return (
    <div>
      {/* <Navigation /> */}
      <CommonNavigation/>

      <div className="about_bg d-flex justify-content-center align-items-center" style={{marginTop:'92px'}}>
        <Fade top>
          <h2 className="text-light fw-bolder text-capitalize">Our Company</h2>
        </Fade>
      </div>

      <div className="container">
        <div className="text-justify fs-5 fw-light my-5">
          {/* <h2 className="text-center my-3 text-muted sub-heading">History</h2>
          <div className="extra-border">
            <p></p>
          </div> */}


          <div className="my-5">
            <Fade top>
              <h2 className="text-center text-muted sub-heading text-capitalize">About Us</h2>
              <div className="extra-border">
                <p></p>
              </div>
            </Fade>
            <p>Digital Decoder is an International Software Company which is leading in Bangladesh in designing innovative solutions for IT Operations, Full-service Software Development, Web Design, and Web Development, Digital Marketing Agency that provides Graphic Design, Digital Advertising, Business Strategy, and Marketing Campaigns to all over the world. We are super experts in Web Development, Mobile Application development (Android and IOS  platforms), Cloud base software, and Desktop-based software. Our most popular innovative services are Accounting Management System, Tour, & Travels Management System, Hospital Management System, HRM Management System, Institution Management System, Documents Clearing, File Converting, Pharmacy Management System, Inventory Management System, Multi-vendor e-commerce, and POS management system.
            <br/>
            Companies around the globe rely on our products to increase efficiency and decrease the costs of their IT production. For three years, we have specialized in automating the change-management process,  providing tools that help software engineers understand the complexities of their information systems. As business operations become overwhelmingly complex, our solutions keep IT simple.
            <br/>
            Our customers all share one thing in common: The desire to have complete control of IT Operations coupled with a solid return on investment.</p>
          </div>

          <hr/>

          <div className="row my-5 py-5">
            <div className=" col-md-6">
              <div>
                <Fade top>
                  <h2 className="text-center text-muted sub-heading text-capitalize">Our mission</h2>
                  <div className="extra-border">
                    <p></p>
                  </div>
                </Fade>
              </div>
              <Fade left>
                <p className="mt-5">As we are the best IT service specialized company we provide IT services until customer satisfaction. Our mission is "Helping Others by IT service Accomplish Theirs ". We always believe that customer satisfaction is our main profit.</p>
              </Fade>
            </div>
            <Fade right>
              <div className="col-md-6">
                <img className="img-fluid" style={{height: '300px', width: '100%'}} src={missionImg} alt='...' />
              </div>
            </Fade>
          </div>


          <div className="row my-5 py-5">
          <Fade left>
            <div className="d-none d-lg-block col-lg-6 ">
              <img className="img-fluid" src={visionImg} alt='...' />
            </div>
          </Fade>

            <div className="col-lg-6">
              <div>
                <Fade top>
                  <h2 className="text-center text-muted sub-heading text-capitalize">Our Vision</h2>
                  <div className="extra-border">
                    <p></p>
                  </div>
                </Fade>
              </div>
              <Fade right>
                <p>Our Vision is to be a leading Web and App Solution company in the IT sector and progress in our current position in the global market. We believe that customer satisfaction is our main profit and Customer growth is our growth, so we commit to our customers to help achieve their business goals. We believe in working with the accuracy and best quality. We want to be known as the reliable, innovative, and user-friendly software service provider in the IT industry.</p>
              </Fade>
            </div>

          </div>



          
          {/* <p>
            Apsis began its business operations as IT solutions and consulting
            company in 2006. Since then, the company is considered as one of the
            fastest growing and leading solution provider for different
            multinational industries like FMCG, Banking, Financial, Supply chain
            and Telecom.
          </p>
          <p>
            Our success and drive in the IT sector led us to expand
            internationally and now we have office in Malaysia call Apsis
            Consulting Services Sdn. Bhd. which is our head quarter for ASEAN
            markets while Bangladesh will remain offshore development center and
            corporate head quarter.
          </p>
          <p>
            The company has been formed by a group of talented professionals
            having vivid experience and wide exposure in Information Technology.
            People involved here are young qualified business graduates and
            qualified engineers from the renowned universities across the globe.
            The resource personnel working in the company have been consistently
            providing reliable support services and consultancy to a wide
            variety of corporate houses either in the capacity of executive or
            as business partner or consultant.
          </p>
          <p>
            It is a company where professionals from both technical and
            functional field group together with an objective of providing fit
            for purpose business solutions. It realizes the importance of
            functional knowledge and its impact in developing business
            solutions. We constantly strive to be a leading technology firm with
            profound business and functional knowledge.
          </p> */}
        </div>
      </div>

      {/* <div style={{ backgroundColor: "#F5F5F5" }}>
        <div className="container d-flex flex-column flex-column-reverse  flex-lg-row justify-content-center align-items-center">
          <Fade left>
            <div className="fs-6 fw-light w-100">
              <p>
                The Apsis solution leverages our experience, in working
                successfully in the Sales &amp; Distribution Industry, Financial
                Industry, Telecom Industry &amp; other large Corporate for the
                past few years. Our solution and expertise mean that Apsis will
                be able to deliver a complete solution working in harmony with
                client’s environment.
              </p>
              <br />

              <div>
                <p>
                  <CgEditBlackPoint className="text-primary" /> We understand
                  your business requirements
                </p>
                <p>
                  <CgEditBlackPoint className="text-primary" /> We understand
                  the business of Enterprise &amp; their business process
                </p>
                <p>
                  <CgEditBlackPoint className="text-primary" /> We have proven
                  mission critical management capability
                </p>
                <p>
                  <CgEditBlackPoint className="text-primary" /> We have proven
                  capability to integrate multiple, complex network elements and
                  to switch transactions reliably and efficiently between them
                </p>
                <p>
                  <CgEditBlackPoint className="text-primary" /> We have proven
                  software integrity, scalability and reliability
                </p>
                <p>
                  <CgEditBlackPoint className="text-primary" /> We have proven
                  capability to deliver on budget and on time
                </p>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="p-4 m-md-5">
              <img className="img-fluid w-100" src={slide} alt="" />
            </div>
          </Fade>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default About;
