import React from 'react';
import { Link } from 'react-router-dom';
// import pageNotFoundImg from './../../images/404.jpg';
import './PageNotFound.css';
import { TbArrowBack } from 'react-icons/tb';

// TbArrowBack

const PageNotFound = () => {
    return (
        <div className='pageNotFoundWrapper  text-center'>
          <Link to="/home"><button className='btn btn-info mt-5'> <TbArrowBack/> Back to home</button></Link>
        </div>
    );
};

export default PageNotFound;